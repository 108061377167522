import { Autocomplete, Button, FormControl, MenuItem, Modal, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useState, useEffect } from 'react';
import { v4 } from 'uuid';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
};

const XmlData = ({ open, handleClose, data, listItems, listAlmoxarifados, listLocalizacao, setCampos, setItens }) => {
    const [rows, setRows] = useState([]);
    const columns = [
        { field: 'codigo', headerName: 'CODIGO', flex: 1 },
        {
            field: 'descricao',
            headerName: 'DESCRICAO',
            flex: 2.3,
            renderCell: (params) => <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{params?.row?.descricao}</div>
        },
        { field: 'unidade', headerName: 'UN', flex: 1 },
        { field: 'quantidade', headerName: 'QTD', flex: 1 },
        { field: 'lote', headerName: 'LOTE', flex: 1 },
        {
            field: 'descricao_sistema',
            headerName: 'DESCRICAO',
            flex: 2,
            renderCell: (params) => (
                <FormControl fullWidth>
                    <Autocomplete
                        options={listItems.filter((item) => !item?.kit_itens)}
                        getOptionLabel={(item) => `${item.id} - ${item?.descricao} - ${!item.apelido ? '' : item?.apelido}`}
                        value={listItems.find((el) => el.id === params?.row?.id_sistema) || null}
                        onChange={(e, newValue) => handleChangeItem(newValue, params.row.uuid)}
                        renderInput={(params) => <TextField {...params} label="Item" />}
                    />
                </FormControl>
            )
        },
        { field: 'unidade_sistema', headerName: 'UN', flex: 1 },
        {
            field: 'quantidade_sistema',
            headerName: 'QTD',
            flex: 1,
            renderCell: (params) => (
                <FormControl fullWidth>
                    <TextField
                        label="QTD"
                        value={params?.row?.quantidade_sistema || ''}
                        onChange={(e) => atualizarCampoItem(params.row.uuid, 'quantidade_sistema', e.target.value)}
                    />
                </FormControl>
            )
        },
        {
            field: 'almoxarifado_sistema',
            headerName: 'ALM',
            flex: 1,
            renderCell: (params) => (
                <FormControl fullWidth>
                    <TextField
                        label="Almox."
                        select
                        value={params?.row?.almoxarifado_sistema || ''}
                        onChange={(e) => atualizarCampoItem(params.row.uuid, 'almoxarifado_sistema', e.target.value)}
                    >
                        {listAlmoxarifados.map((almoxarifado) => (
                            <MenuItem key={almoxarifado.id} value={almoxarifado.id}>
                                {almoxarifado.descricao}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )
        },
        {
            field: 'localizacao_sistema',
            headerName: 'LOC',
            flex: 1,
            renderCell: (params) => (
                <FormControl fullWidth>
                    <TextField
                        label="Local."
                        select
                        value={params?.row?.localizacao_sistema || ''}
                        onChange={(e) => atualizarCampoItem(params.row.uuid, 'localizacao_sistema', e.target.value)}
                    >
                        {listLocalizacao.map((localizacao) => (
                            <MenuItem key={localizacao.id} value={localizacao.id}>
                                {localizacao.descricao}
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )
        },
        {
            field: 'lote_sistema',
            headerName: 'LOTE',
            flex: 1,
            renderCell: (params) => (
                <FormControl fullWidth>
                    <TextField
                        label="Lote"
                        value={params?.row?.psicotropico_sistema ? params?.row?.lote_sistema : 'N/A'}
                        disabled={!params?.row?.psicotropico_sistema}
                        onChange={(e) => atualizarCampoItem(params.row.uuid, 'lote_sistema', e.target.value)}
                    />
                </FormControl>
            )
        }
    ];

    function handleChangeItem(selectedItem, uuid) {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.uuid === uuid
                    ? {
                          ...row,
                          id_sistema: selectedItem?.id,
                          descricao_sistema: selectedItem?.descricao,
                          unidade_sistema: selectedItem?.unidade?.descricao,
                          almoxarifado_sistema: selectedItem?.almoxarifado?.id,
                          localizacao_sistema: selectedItem?.localizacao?.id,
                          psicotropico_sistema: selectedItem?.psicotropico ? true : false
                      }
                    : row
            )
        );
    }

    function atualizarCampoItem(uuid, campo, valor) {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.uuid === uuid
                    ? {
                          ...row,
                          [campo]: valor
                      }
                    : row
            )
        );
    }

    function handleConfirmItems(data, rows) {
        const date = new Date();
        const currentDay = String(date.getDate()).padStart(2, '0');
        const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
        const currentYear = date.getFullYear();
        const currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        const body = {
            documento: data?.numero_documento,
            emissao: data?.data_emissao,
            movimento: currentDate,
            fornecedor: data?.fornecedor?.id,
            observacao: '',
            itens: rows.map((element) => ({
                uuid: element?.uuid,
                item_id: element?.id_sistema,
                descricao: element?.descricao_sistema,
                quantidade: element?.quantidade_sistema,
                unidade: element?.unidade_sistema,
                valor_unitario: parseFloat(element?.valor_unitario),
                valor_total: parseFloat(element?.valor_total),
                almoxarifado_id: element?.almoxarifado_sistema,
                localizacao_id: element?.localizacao_sistema,
                lote: element?.lote_sistema,
                psicotropico: element?.psicotropico_sistema,
                validade: '',
                observacao: ''
            }))
        };
        setCampos(body);
        setItens((prevItens) => [...prevItens, ...body?.itens]);
        handleClose();
    }

    useEffect(() => {
        if (open) {
            const dadosFormatados = data.itens.map((element) => ({
                ...element,
                uuid: v4(),
                id_sistema: '',
                descricao_sistema: '',
                unidade_sistema: '',
                quantidade_sistema: element?.quantidade,
                almoxarifado_sistema: '',
                localizacao_sistema: '',
                lote_sistema: '',
                psicotropico_sistema: false
            }));
            setRows(dadosFormatados);
        }
    }, [open]);

    return (
        <Modal open={open}>
            <Box sx={style}>
                <Box sx={{ width: '100%', display: 'flex', gap: '1rem' }}>
                    <FormControl fullWidth>
                        <TextField value={data?.numero_documento} label="Nota" disabled />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField value={data?.data_emissao} label="Data Emissão" disabled />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField value={`${data?.fornecedor.nome_fantasia} - ${data?.fornecedor?.cnpj}`} label="Fornecedor" disabled />
                    </FormControl>
                </Box>
                <Box sx={{ width: '100%', margin: '1rem 0 1rem 0' }}>
                    <DataGrid
                        columns={columns}
                        rows={rows}
                        hideFooter
                        getRowId={(row) => row.codigo}
                        rowHeight={75}
                        disableRowSelectionOnClick
                        disableColumnMenu
                    />
                </Box>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ width: '30%', display: 'flex', justifyContent: 'space-between' }}>
                        <TextField value={data?.imposto} label="Imposto" disabled />
                        <TextField value={data?.desconto} label="Desconto" disabled />
                    </Box>
                    <Box sx={{ width: '30%', display: 'flex', justifyContent: 'space-between' }}>
                        <TextField value={data?.valor_nota} label="Valor Nota" disabled />
                        <Button
                            color="error"
                            variant="contained"
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button variant="contained" onClick={() => handleConfirmItems(data, rows)}>
                            Confirmar
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default XmlData;
