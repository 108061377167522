import { Button, CircularProgress, Grid, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { PageOne } from './components/PageOne';
import { useState } from 'react';
import { useEffect } from 'react';
import { useApi } from 'Service/axios';
import { DataGrid } from '@mui/x-data-grid';
import formater from 'utils/DateDDMMYYY';
import { Delete, ArchiveOutlined, Send, Check, TimeToLeave } from '@mui/icons-material';
import TempoMedio from 'components/Triagem/Cards/TempoMedio';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import Aguardando from 'components/Triagem/Cards/Aguardando';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { IconClock } from '@tabler/icons';
import { formatarData } from 'utils/formatarData';
import Laco from '../../../assets/images/Logos/laco.jpg';
import { ChamadaContext } from 'context/ChamadasContext';
import { useContext } from 'react';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import { Box } from '@mui/system';

export function MedicalAppointment() {
    const api = useApi();
    const { setChamadaAcionada, setChamadasRealizadas } = useContext(ChamadaContext);
    const [cooldown, setCooldown] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [clinicName, setClinicName] = useState('');
    const [rows, setRows] = useState([]);
    const [defaultRows, setDefaultRows] = useState([]);
    const [waitTime, setWaitTime] = useState('');
    const [averageWaitTime, setAverageWaitTime] = useState('');
    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const navigate = useNavigate();
    const columns = [
        { field: 'ficha', headerName: 'F.A', flex: 0.5 },
        {
            field: 'paciente',
            headerName: 'Paciente',
            flex: 1.5,
            renderCell: (params) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    {params.row.paciente}
                    {params.row.autismo && <img src={Laco} alt="Laco" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />}
                </div>
            )
        },
        { field: 'idade', headerName: 'Idade', flex: 0.5 },
        { field: 'data', headerName: 'Data', flex: 0.5 },
        { field: 'hora', headerName: 'Hora', flex: 0.5 },
        { field: 'status', headerName: 'Status', flex: 0.5 },
        {
            field: 'enfermagem',
            headerName: 'Medicacao',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    {params.row.statusMedicacao === 'APLICADO' ? (
                        <Check />
                    ) : params.row.statusMedicacao === 'SEPARACAO' || params.row.statusMedicacao === 'PARCIAL' ? (
                        <IconClock />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'lab',
            headerName: 'Exames',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    {params.row.statusLaboratorio === 'COLETA' || params.row.statusLaboratorio === 'PARCIAL' ? (
                        <IconClock />
                    ) : params.row.statusLaboratorio === 'REALIZADO' ? (
                        <Check />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            field: 'raiox',
            headerName: 'Raio-x',
            flex: 0.5,
            renderCell: (params) => <>{params.row.statusRaiox == null ? '-' : params.row.statusRaiox ? <Check /> : <IconClock />} </>
        },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Chamar">
                            <IconButton onClick={() => handleCreateChamada(params.row)}>
                                <Send />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Ficha">
                            <IconButton onClick={() => handleLinkProntuario(params.row)}>
                                <ArchiveOutlined />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    // Realiza o cadastro da chamada
    // e recebe o objeto pelo socket no contexto automaticamente
    const handleCreateChamada = async (item) => {
        try {
            // Controla o disabled dos botões
            setCooldown(true);
            // Cadastra a chamada no banco
            const payloadChamada = { setor: clinicName?.descricao, prontuario_id: item?.ficha, message: 'TESTE' };
            const criarChamada = await api.createChamadaPainel(payloadChamada);

            if (criarChamada.status == 201) {
                setTimeout(() => {
                    setCooldown(false);
                }, 3000);
                // State do context
                // Se true ele chama o get para atualizar os atendimentos pendentes

                setChamadaAcionada(true);

                toast.success('Paciente Chamado com sucesso!');
            }
        } catch (error) {
            toast.error(`Erro ao chamar paciente: ${error.message}`);
        }
    };

    async function getTable() {
        try {
            const { data } = await api.getPatientsByClinic();
            console.log('🚀 ~ getTable ~ data:', data);
            const row = data.map((item) => ({
                ...item,
                ficha: item?.id ?? '-',
                fa: item?.paciente?.id ?? '-',
                idade: item?.paciente?.idade ?? '-',
                data: item?.data ?? '-',
                data_atendimento: item?.data_atendimento ?? '-',
                hora: item?.hora_atendimento ? item.hora_atendimento.slice(0, 5) : '-',
                status: item?.status ?? '-',
                paciente: item?.paciente?.nome_completo ?? '-',
                enfermagem: ' - ',
                lab:
                    item?.todasCondutasFinalizadasPorTipo?.laboratorio !== undefined
                        ? item.todasCondutasFinalizadasPorTipo.laboratorio
                            ? 'Finalizado'
                            : 'Pendente'
                        : '-',
                raiox:
                    item?.todasCondutasFinalizadasPorTipo?.raiox !== undefined
                        ? item.todasCondutasFinalizadasPorTipo.raiox
                            ? 'Finalizado'
                            : 'Pendente'
                        : '-',
                autismo: item?.paciente?.autismo ?? '-'
            }));

            setRows(row);
            setDefaultRows(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    function handleNavigateMedicalRecord(row) {
        navigate(`/main/consultorio/ficha/${row.ficha}/${row.fa}/${row.paciente}`);
    }

    async function handleLinkProntuario(row) {
        const userId = localStorage.getItem('id');
        const payload = { prontuario_id: row.ficha, consultorio_id: clinicName?.id, usuario_id: userId };

        try {
            await api.linkProntuarioToPatient(payload);
            handleNavigateMedicalRecord(row);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        const isExpired = localStorage.getItem('login_expired');
        const boolenExpired = JSON.parse(isExpired);
        const clinicObj = JSON.parse(localStorage.getItem('consultorio'));
        setClinicName(clinicObj);
        setIsExpired(boolenExpired);
    }, [isExpired]);

    // CALCULO DE TEMPO DE ESPERA
    useEffect(() => {
        if (rows.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(rows[0]?.data_atendimento);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [rows]);

    // CALCULO DE TEMPO MEDIO
    useEffect(() => {
        if (rows.length > 0) {
            const calculateAverageWaitTime = () => {
                const currentDate = new Date();
                const totalWaitTimeInSeconds = rows.reduce((accumulator, record) => {
                    const recordDate = new Date(record.data_atendimento);
                    const timeDifference = currentDate.getTime() - recordDate.getTime();
                    return accumulator + Math.floor(timeDifference / 1000);
                }, 0);

                const averageWaitTimeInSeconds = totalWaitTimeInSeconds / rows.length;
                const hours = Math.floor(averageWaitTimeInSeconds / 3600);
                const minutes = Math.floor((averageWaitTimeInSeconds % 3600) / 60);
                const seconds = Math.floor(averageWaitTimeInSeconds % 60);

                setAverageWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateAverageWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [rows]);

    useEffect(() => {
        const getData = () => {
            getTable();
        };
        getData();
        const interval = setInterval(getData, 10000);
        return () => clearInterval(interval);
    }, []);

    function resetIsExpiredAndLogoff() {
        localStorage.setItem('login_expired', true);
        setIsExpired(true);
    }

    useEffect(() => {
        if (checked) {
            setRows(defaultRows.filter((i) => i?.interno));
        } else {
            setRows(defaultRows.filter((i) => !i?.interno));
        }
    }, [checked]);

    return (
        <MainCard title={isExpired ? 'Atendimento' : clinicName?.descricao}>
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                {isExpired && <PageOne setIsExpired={setIsExpired} />}
                {/* {!isExpired && (
                    
                )} */}
                {!isExpired && (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                position: 'absolute',
                                top: '-93px',
                                right: 0
                            }}
                        >
                            <TempoMedio tempoMedio={averageWaitTime} />
                            <TempoEspera tempoEspera={waitTime} />
                            <Aguardando aguardandoQTD={rows.length} />
                        </div>
                        <div style={{ height: 600, width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Box sx={{ marginLeft: 'auto' }}>
                                <Button variant="contained" onClick={resetIsExpiredAndLogoff} sx={{ marginBottom: '1rem' }}>
                                    Trocar consultório
                                </Button>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '.4rem', marginLeft: 'auto', alignItems: 'center', marginLeft: 'auto' }}>
                                <Typography>Interno</Typography>
                                <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                            </Box>
                            <DataGrid rows={rows} columns={columns} pageSize={5} disableSelectionOnClick />
                        </div>
                    </>
                )}
            </Grid>
        </MainCard>
    );
}
