import {
    Button,
    Checkbox,
    Divider,
    Fab,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    IconButton,
    TextField,
    Tooltip
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useApi } from 'Service/axios';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { formatarDataIdade } from 'utils/dataIdade';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {
    IconLungs,
    IconArrowAutofitHeight,
    IconThermometer,
    IconEye,
    IconFileAnalytics,
    IconPercentage,
    IconPill,
    IconCheck,
    IconArrowLeft
} from '@tabler/icons';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import CloseIcon from '@mui/icons-material/Close';
import MonitorWeightIcon from '@mui/icons-material/MonitorWeight';
import CalculateIcon from '@mui/icons-material/Calculate';
import { Check, Print } from '@mui/icons-material';
import { IconClock, IconTimeDuration0 } from '@tabler/icons-react';
import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { DoctorsSignatureConfirmationModal } from 'components/DoctorsSignatureConfirmationModal';
import { NurseSignature } from './component/Signature';
import { SignatureLab } from './component/SignatureLab';
import { ModalRoom } from './component/ModalRoom';

export function GeneralNursing() {
    const api = useApi();
    const [prontuarioData, setProntuarioData] = useState([]);
    const { prontuarioId } = useParams();
    const [medicine, setMedicine] = useState({});
    const [triagem, setTriagem] = useState({});
    const [paciente, setPaciente] = useState({});
    const [medicalRecordObs, setMedicalRecordObs] = useState({});
    const [medication, setMedication] = useState([]);
    const [labData, setLabData] = useState([]);
    const [nurseReport, setNurseReport] = useState([]);
    const [allusers, setAllUsers] = useState([]);

    const [assinaturaId, setAssinaturaId] = useState(null);
    const [openSignature, setOpenSignature] = useState(false);
    const [selectedMedAply, setSelectedMedAply] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [isRecivedSelected, setIsRecivedSelected] = useState(false);

    const [openFinishedLab, setOpenFinishedLab] = useState(false);
    const navigate = useNavigate();

    async function getProntuario() {
        try {
            const { data } = await api.getProntuarioById(prontuarioId);

            setProntuarioData(data);
        } catch (error) {
            console.log('🚀 ~ getProntuario ~ error:', error);
        }
    }

    async function getRecord() {
        try {
            const { data } = await api.getMedicalRecord(prontuarioId, prontuarioData?.paciente?.id);
            setMedicine(data?.medicacao);
            setTriagem(data?.triagem);
            setPaciente(prontuarioData?.paciente);
            handleMedicalRecord();
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleMedicalRecord() {
        try {
            const { data } = await api.getMedicalRecordObservation(prontuarioId);
            setMedicalRecordObs(data);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    useEffect(() => {
        getProntuario();
        handleMedicalRecord();
    }, []);

    useEffect(() => {
        getRecord();
    }, [prontuarioData]);

    async function getAllConduct() {
        try {
            const { data } = await api.getAllPendentesByProntuario(prontuarioId);
            console.log('🚀 ~ getAllConduct ~ data:', data);

            const medicationFIltered = data?.filter((item) => item?.tipo === 'MEDICAMENTO');
            const rowsMedication = medicationFIltered?.map((item) => ({
                ...item,
                id: item?.id,
                descricao: item?.conduta_medica?.item_conduta?.item_pedido?.descricao,
                qtd: item?.conduta_medica?.item_conduta?.quantidade_item,
                unidade: item?.conduta_medica?.item_conduta.item_pedido?.unidade?.descricao,
                aplicacao: item?.conduta_medica?.item_conduta?.via_aplicacao
            }));
            setMedication(rowsMedication);

            const labFIltered = data.filter((item) => item?.tipo === 'LABORATORIO');
            const rowsLab = labFIltered.map((item) => ({
                ...item,
                id: item?.id,
                descricao: item?.conduta_medica?.item_conduta?.exame?.nome,
                status: item?.status
            }));
            setLabData(rowsLab);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function users() {
        try {
            const { data } = await api.getAllUsers();
            setAllUsers(data);
        } catch (error) {
            console.log('🚀 ~ users ~ error:', error);
        }
    }

    async function getNurseReport() {
        try {
            const { data } = await api.getAllNurseReport(prontuarioId);
            console.log('🚀 ~ getAllNurseReport ~ data:', data);
            const rows = data?.map((item) => {
                return {
                    ...item,
                    data: item?.dia,
                    hora: item?.hora,
                    tipo: item?.tipo,
                    user: allusers.usuarios?.find((user) => user?.id === item?.responsavel_id)?.nome
                };
            });
            setNurseReport(rows);
        } catch (error) {
            toast.error(error?.response?.data?.message ?? error?.message);
        }
    }

    async function handleAplyMedication() {
        const toastID = toast.loading('Atualizando...');
        const userInfo = localStorage.getItem('userInfos');
        const user = JSON.parse(userInfo);
        const payload = selectedMedAply.map((item) => {
            return {
                id: item?.id,
                responsavel_id: user?.usuario?.id,
                assinatura_id: assinaturaId,
                prontuarioId: prontuarioId
            };
        });
        console.log('🚀 ~ payload ~ payload:', payload);

        // const payload = {
        //     id: selectedRow?.id,
        //     responsavel_id: user?.usuario?.id,
        //     assinatura_id: assinaturaId,
        //     prontuarioId: prontuarioId
        // };
        try {
            await api.updateMedicationFinished(payload);
            getAllConduct();
            toast.update(toastID, { render: 'Atualizado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            console.log('🚀 ~ handleAplyMedication ~ error:', error);
            toast.update(toastID, { render: error?.response?.data?.message, type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    const columns = [
        { field: 'data', headerName: 'DATA', flex: 1 },
        { field: 'hora', headerName: 'HORA', flex: 1 },
        { field: 'tipo', headerName: 'TIPO', flex: 0.8 },
        { field: 'user', headerName: 'RESPONSÁVEL', flex: 1 },
        { field: 'conteudo', headerName: 'CONTEUDO', flex: 2 }
    ];

    useEffect(() => {
        getAllConduct();
        users();
    }, []);

    useEffect(() => {
        getNurseReport();
    }, [allusers]);

    async function handleReciveMedication() {
        const toastID = toast.loading('Atualizando...');
        const payload = selectedRow.map((item) => {
            return {
                id: item?.id,
                prontuarioId: prontuarioId
            };
        });

        try {
            await api.updateMedicationReceived(payload);
            getAllConduct();
            // setIsRecivedSelected(false);
            // setIsRecivedSelected(true);
            toast.update(toastID, { render: 'Atualizado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
        } catch (error) {
            console.log('🚀 ~ handleReciveMedication ~ error:', error);
            toast.update(toastID, { render: error?.response?.data?.message, type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    const columnsMedication = [
        { field: 'id', headerName: 'CÓD', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        { field: 'qtd', headerName: 'QUANTIDADE', flex: 0.5 },
        { field: 'unidade', headerName: 'UNIDADE', flex: 0.5 },
        { field: 'aplicacao', headerName: 'APLICAÇÃO', flex: 0.5 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 1,
            renderCell: (params) => (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={params.row.status === 'ATENDIMENTO' || params.row.status === 'APLICADO'}
                                onClick={() => {
                                    setSelectedRow((prev) => {
                                        const unicChecked = prev.some((item) => item.id === params.row.id);
                                        if (unicChecked) {
                                            return prev.filter((item) => item.id !== params.row.id);
                                        }
                                        return [...prev, params.row];
                                    });
                                    setIsRecivedSelected(true);
                                }}
                            />
                        }
                        label="Recebido"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={params.row.status === 'SEPARACAO' || params.row.status === 'APLICADO'}
                                onClick={() => {
                                    setSelectedMedAply((prev) => {
                                        const unicChecked = prev.some((item) => item.id === params.row.id);
                                        if (unicChecked) {
                                            return prev.filter((item) => item.id !== params.row.id);
                                        }
                                        return [...prev, params.row];
                                    });

                                    setIsRecivedSelected(false);
                                }}
                            />
                        }
                        label="Aplicado"
                    />
                </>
            )
        }
    ];

    const [selectedLabColeta, setSelectedLabColeta] = useState([]);

    const columnsLab = [
        { field: 'id', headerName: 'CÓD', flex: 0.5 },
        { field: 'descricao', headerName: 'DESCRIÇÃO', flex: 1 },
        {
            field: 'actions',
            headerName: 'COLETA',
            flex: 0.5,
            renderCell: (params) => (
                <>
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={params.row.status === 'COLETADO' || params.row.status === 'REALIZADO'}
                                onClick={() => {
                                    setSelectedLabColeta((prev) => {
                                        const unicChecked = prev.some((item) => item.id === params.row.id);
                                        if (unicChecked) {
                                            return prev.filter((item) => item.id !== params.row.id);
                                        }
                                        return [...prev, params.row];
                                    });
                                }}
                            />
                        }
                        label="Coletado"
                    />
                </>
            )
        }
    ];

    async function handleFinishedLab() {
        const toastID = toast.loading('Atualizando...');
        const userInfo = localStorage.getItem('userInfos');
        const user = JSON.parse(userInfo);
        const payload = selectedLabColeta.map((item) => {
            return {
                id: item?.id,
                responsavel_id: user?.usuario?.id,
                assinatura_id: assinaturaId,
                prontuarioId: prontuarioId
            };
        });

        try {
            await api.updateLaboratoryFinished(payload);
            getAllConduct();
            toast.update(toastID, { render: 'Atualizado com sucesso', type: 'success', isLoading: false, autoClose: 3000 });
            setSelectedLabColeta([]);
        } catch (error) {
            console.log('🚀 ~ handleFinishedLab ~ error:', error);
            toast.update(toastID, { render: error?.response?.data?.message, type: 'error', isLoading: false, autoClose: 3000 });
        }
    }

    return (
        <MainCard title={`Enfermagem - ${prontuarioData?.paciente?.nome_completo}`}>
            <NurseSignature
                confirmFunction={isRecivedSelected ? handleReciveMedication : handleAplyMedication}
                setAssinaturaId={setAssinaturaId}
                open={openSignature}
                setOpen={setOpenSignature}
            />

            <SignatureLab
                confirmFunction={handleFinishedLab}
                setAssinaturaId={setAssinaturaId}
                open={openFinishedLab}
                setOpen={setOpenFinishedLab}
            />
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', position: 'absolute', right: 0, top: '-60px' }}>
                        <p>
                            PRONTUÁRIO: <b>{triagem?.prontuario_id}</b>
                        </p>
                        <p>
                            FICHA DE ATENDIMENTO: <b> {medicine?.paciente_id}</b>
                        </p>
                        <p>
                            DATA: <b> {triagem?.data}</b>
                        </p>
                        <p>
                            IDADE: <b> {formatarDataIdade(paciente?.data_nascimento)}</b>
                        </p>
                    </Box>
                    <Box sx={{ borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', padding: '10px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <h4>ESCUTA INICIAL</h4>
                            <p>
                                HORA: <b>{triagem?.hora}</b>
                            </p>

                            <h4>
                                RESPONSÁVEL: <b>{triagem?.usuario?.nome}</b>
                            </h4>
                        </Box>
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MonitorHeartIcon />
                                <TextField
                                    label="SIS"
                                    type="number"
                                    value={triagem?.pa_1}
                                    name="pa_sis"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px', marginLeft: '.5rem' }}
                                />
                                <CloseIcon sx={{ margin: '0 5px' }} />
                                <TextField
                                    label="DIA"
                                    type="number"
                                    placeholder="DIA"
                                    value={triagem?.pa_2}
                                    name="pa_dia"
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FavoriteIcon />
                                <TextField
                                    label="FC"
                                    type="number"
                                    value={triagem?.fc}
                                    name="fc"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconLungs />
                                <TextField
                                    label="FR"
                                    type="number"
                                    value={triagem?.fr}
                                    name="fc"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <VaccinesIcon />
                                <TextField
                                    label="DEXTRO"
                                    type="number"
                                    value={triagem?.dextro}
                                    name="fc"
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MonitorWeightIcon />
                                <TextField
                                    disabled
                                    label="Peso"
                                    name="peso"
                                    type="text"
                                    value={triagem.peso}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px', marginLeft: '.5rem' }}
                                />
                                <IconArrowAutofitHeight sx={{ margin: '0 5px' }} />
                                <TextField
                                    disabled
                                    label="Altura"
                                    name="altura"
                                    type="text"
                                    value={triagem.altura}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '130px', marginLeft: '.5rem' }}
                                />
                            </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CalculateIcon />
                                <TextField
                                    label="IMC"
                                    name="IMC"
                                    type="number"
                                    value={triagem.imc}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconThermometer />
                                <TextField
                                    label="Temperatura"
                                    name="temperatura"
                                    type="text"
                                    value={triagem?.temperatura}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <IconPercentage />
                                <TextField
                                    label="Saturação"
                                    name="saturacao"
                                    type="number"
                                    value={triagem?.saturacao}
                                    disabled
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ width: '120px', marginLeft: '.5rem' }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ marginTop: '10px' }}>
                            QUEIXA: <b>{triagem?.queixa ? triagem?.queixa : 'QUEIXA NÃO INFORMADA'}</b>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <p>
                                MÉDICAÇÃO EM USO:{' '}
                                {medicine?.c?.length === 0
                                    ? 'NEGA'
                                    : medicine?.medicacao_em_uso?.map((item, index, array) => (
                                          <span key={index}>{array.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                                      ))}
                            </p>
                            <p>
                                ALERGIA:{' '}
                                {medicine?.alergia?.length === 0
                                    ? 'NEGA'
                                    : medicine?.alergia?.map((item, index, array) => (
                                          <span key={index}>{array.length - 1 === index ? <b>{item}</b> : <b>{item} |</b>} </span>
                                      ))}
                            </p>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ borderRadius: '7px', border: '1px solid #ccc', padding: '10px', marginTop: '20px' }}>
                    <Box>
                        <h3>Ficha médica</h3>
                    </Box>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        label="Observação médica"
                        disabled
                        fullWidth
                        multiline
                        rows={5}
                        value={medicalRecordObs?.conteudo}
                    />
                </Box>

                <Box>
                    <h3 style={{ textAlign: 'center' }}>Ficha médica</h3>
                    <Divider />
                    {medication.length > 0 && (
                        <Box>
                            <h3>Medicação</h3>
                            <DataGrid rows={medication} sx={{ maxHeight: '400px' }} columns={columnsMedication} hideFooter />
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', alignItems: 'center', gap: '40px' }}>
                                <Button
                                    disabled={selectedRow.length == 0 || selectedMedAply.length > 0}
                                    variant="contained"
                                    onClick={() => {
                                        setOpenSignature(true);
                                    }}
                                >
                                    Receber
                                </Button>
                                <Button
                                    disabled={selectedMedAply.length == 0 || selectedRow.length > 0}
                                    variant="contained"
                                    onClick={() => setOpenSignature(true)}
                                >
                                    Aplicar
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {labData.length > 0 && (
                        <Box sx={{ marginTop: '50px' }}>
                            <h3>Laboratório</h3>
                            <DataGrid rows={labData} sx={{ maxHeight: '400px' }} columns={columnsLab} hideFooter />
                            <Button variant="contained" disabled={selectedLabColeta.length == 0} onClick={() => setOpenFinishedLab(true)}>
                                Coletar Laboratório
                            </Button>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '50px', marginBottom: '50px', gap: '20px' }}>
                        {prontuarioData?.salas_enfermagem?.descricao ? (
                            <p>Sala de espera: {prontuarioData?.salas_enfermagem?.descricao}</p>
                        ) : (
                            ''
                        )}
                        <ModalRoom getProntuario={getProntuario} />
                    </Box>
                    {nurseReport.length > 0 && (
                        <Box>
                            <h3>Relatório de Acompanhamento</h3>
                            <DataGrid
                                // getRowHeight={() => 'auto'}
                                rows={nurseReport}
                                sx={{ maxHeight: '400px' }}
                                columns={columns}
                                hideFooter
                            />
                        </Box>
                    )}
                    <Fab variant="extended" sx={{ position: 'fixed', bottom: '20px', right: '20px' }} onClick={() => navigate(-1)}>
                        <IconArrowLeft />
                        Voltar
                    </Fab>
                </Box>
            </Grid>
        </MainCard>
    );
}
