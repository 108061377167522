import { Grid, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { IconArchive, IconArticle, IconEdit, IconTrash } from '@tabler/icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { MonitoringReportModal } from './components/MonitoringReportModal';
import { useNavigate } from 'react-router';
import TempoMedio from 'components/Triagem/Cards/TempoMedio';
import TempoEspera from 'components/Triagem/Cards/TempoEspera';
import Aguardando from 'components/Triagem/Cards/Aguardando';

export function NursingService() {
    const api = useApi();
    const [NurseService, setNurseService] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [checked, setChecked] = useState(false);

    function handleChandeChecked() {
        setChecked(!checked);
    }
    const navigate = useNavigate();

    async function getAllNurse() {
        try {
            const { data } = await api.getAllNurse();
            console.log('🚀 ~ getAllNurse ~ data:', data);
            const rows = data.map((item) => ({
                ...item,
                id: item?.conduta_medica?.prontuario?.paciente?.id,
                age: item?.conduta_medica?.prontuario?.paciente?.idade,
                name: item?.conduta_medica?.prontuario?.paciente?.nome_completo,
                data: item.dia,
                hora: item.hora,
                status: translateStatus(item.conduta_medica?.prontuario?.status),
                prontuario_id: item?.prontuario_id
            }));
            setNurseService(rows);
        } catch (error) {
            console.log('🚀 ~ getAllNurse ~ error:', error);
        }
    }

    function translateStatus(status) {
        switch (status) {
            case 'MEDICACAO':
                return 'RETORNO';
            default:
                return status;
        }
    }

    useEffect(() => {
        getAllNurse();
    }, []);

    const columns = [
        { field: 'prontuario_id', headerName: 'F.A', flex: 0.5 },
        { field: 'name', headerName: 'Paciente', flex: 1 },
        { field: 'age', headerName: 'Idade', flex: 0.5 },
        { field: 'data', headerName: 'Data', flex: 0.5 },
        { field: 'hora', headerName: 'Hora', flex: 0.5 },
        { field: 'status', headerName: 'Status', flex: 0.5 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.3,
            renderCell: (params) => (
                <>
                    <Tooltip title="Acompanhamento">
                        <IconButton
                            onClick={() => {
                                setSelectedRow(params.row);
                                setOpenModal(true);
                            }}
                        >
                            <IconArticle />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Visualizar geral">
                        <IconButton onClick={() => navigate(`/main/enfermagem/geral/${params.row.prontuario_id}`)}>
                            <IconArchive />
                        </IconButton>
                    </Tooltip>
                </>
            )
        }
    ];
    const [averageWaitTime, setAverageWaitTime] = useState('');
    const [waitTime, setWaitTime] = useState('');

    useEffect(() => {
        if (NurseService.length > 0) {
            const calculateWaitTime = () => {
                const recordDate = new Date(NurseService[0]?.createdAt);
                const currentDate = new Date();
                const timeDifference = currentDate.getTime() - recordDate.getTime();
                const waitTimeInSeconds = Math.floor(timeDifference / 1000);

                const hours = Math.floor(waitTimeInSeconds / 3600);
                const minutes = Math.floor((waitTimeInSeconds % 3600) / 60);
                const seconds = waitTimeInSeconds % 60;

                setWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [NurseService]);
    useEffect(() => {
        if (NurseService.length > 0) {
            const calculateAverageWaitTime = () => {
                const currentDate = new Date();
                const totalWaitTimeInSeconds = NurseService.reduce((accumulator, record) => {
                    const recordDate = new Date(record.createdAt);
                    const timeDifference = currentDate.getTime() - recordDate.getTime();
                    return accumulator + Math.floor(timeDifference / 1000);
                }, 0);

                const averageWaitTimeInSeconds = totalWaitTimeInSeconds / NurseService.length;
                const hours = Math.floor(averageWaitTimeInSeconds / 3600);
                const minutes = Math.floor((averageWaitTimeInSeconds % 3600) / 60);
                const seconds = Math.floor(averageWaitTimeInSeconds % 60);

                setAverageWaitTime(`${hours}h ${minutes}m ${seconds}s`);
            };

            const intervalId = setInterval(calculateAverageWaitTime, 1000);

            return () => clearInterval(intervalId);
        }
    }, [NurseService]);
    return (
        <MainCard title="ENFERMAGEM">
            <Grid spacing={gridSpacing} sx={{ position: 'relative' }}>
                <MonitoringReportModal open={openModal} setOpen={setOpenModal} patient={selectedRow} />

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        position: 'absolute',
                        top: '-93px',
                        right: 0
                    }}
                >
                    <TempoMedio tempoMedio={averageWaitTime} />
                    <TempoEspera tempoEspera={waitTime} />
                    <Aguardando aguardandoQTD={NurseService?.length} />
                </div>

                <Box>
                    <Box sx={{ display: 'flex', gap: '.4rem', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Typography>Interno</Typography>
                        <Switch checked={checked} onChange={handleChandeChecked} inputProps={{ 'aria-label': 'controlled' }} />
                    </Box>
                    {checked ? (
                        <DataGrid
                            rows={NurseService.filter((item) => item?.conduta_medica?.prontuario?.interno === 1)}
                            columns={columns}
                            hideFooter
                        />
                    ) : (
                        <DataGrid rows={NurseService} columns={columns} hideFooter />
                    )}
                </Box>
            </Grid>
        </MainCard>
    );
}
